import React from "react";
import "./PrivacyBanner.css";
import Fade from "../../animations/Fade";

export default function privacyBanner() {
  return (
    <div className="flex flex-col xs:mt-40 xs:space-y-12 sm:mt-40 sm:space-y-12 mt-96 space-y-24">
      <div className="flex flex-col text-md">
        <Fade direction={"bottomToTop"} duration={1}>
          <span>Last updated</span>
        </Fade>
        <Fade direction={"bottomToTop"} duration={1}>
          <span>OCTOBER 2021</span>
        </Fade>
      </div>
      <Fade direction={"leftToRight"} duration={1}>
        <h1 className="heading text-2xl privacy-banner">Algomint Privacy</h1>
      </Fade>
    </div>
  );
}
