import React from "react";
import "./PolicyItems.css";
import Fade from "../../animations/Fade";
export default function policyPoints({ title, subHeading, points }) {
  return (
    <Fade direction={"bottomToTop"} duration={1}>
      <div className="policyPoints-wrapper flex flex-col w-full ">
        <div className="xs:text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl  font-light uppercase text-[#e8e8e833]">
          {title}
        </div>
        <div className="mt-4">{subHeading}</div>
        <div>
          <ul className="list-disc p-8">
            {points.map((point, index) => {
              return (
                <li
                  key={index}
                  className="font-normal leading-6 my-4 text-[#e8e8e8] policy-list-item"
                >
                  {point}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Fade>
  );
}
