import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import PrivacyBanner from "../components/PrivacyBanner";
import PolicyPoints from "../components/PolicyItems";
import { PolicyItems } from "../components/data/PolicyItems";
import { Helmet } from "react-helmet";
import Fade from "../animations/Fade";

export default function TokenLaunch() {
  return (
    <div>
      <Layout>
        <Helmet>
          <title>Algomint | Privacy Policy</title>
          <script>
            {`
              !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
              posthog.init('phc_igoJY4YFsqvSyr3ZG8RCPw8bzWMMtumGFJsylr0T9bz', {api_host: 'https://app.posthog.com'})
            `}     
          </script>
        </Helmet>
        <PrivacyBanner />
        <br />
        <div className="flex xs:flex-col sm:flex-col justify-between mt-36">
          <div className="xs:text-3xl sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl tracking-tighter font-medium uppercase">
            Privacy Policy
          </div>
          <Fade direction={"bottomToTop"} duration={1}>
            <div
              style={{
                wordSpacing: "3px",
                fontFamily: "Space Mono,sans-serif",
              }}
            >
              Last modified on 23rd October 2021
            </div>
          </Fade>
        </div>
        <br />
        <br />
        <br />
        {PolicyItems.map(({ title, subHeading, points }) => {
          return (
            <PolicyPoints
              title={title}
              subHeading={subHeading}
              points={points}
            />
          );
        })}
      </Layout>
    </div>
  );
}
